/* ///////////////////////////////////////////////// Destination section-1 //////////////////////////////////////////////////////// */

.Hotel-banner-image {
    /* border: 1px solid red; */
    height: 86vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../Images/Chitwan Nepal4.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Hotel-banner-image img {
    height: 100%;
    width: 100%;
}


/* ///////////////////////////////// Section 2 /////////////////////////////////// */


.Hotel-List {
    margin-top: 10vh;
}

.Hotel-text {
    margin-top: 10vh;
    margin-bottom: 10vh;
    background-color: #808080;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 20px;
}

.Hotel-text h4 {
    font-size: 25px;
    font-weight: bold;
}

.Hotel-body {
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    margin-bottom: 10vh;
}

.Hotel-Images {
    margin: 20px;
    height: 40vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    background-color: #efefef;

}

.Hotel-Images img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Hotel-details {
    margin: 20px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    background-color: #efefef;
}

.Hotel-Info h4 {
    margin: 10px;
    letter-spacing: 2px;
    font-family: 'Crimson Text', serif;
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
}

.Hotel-Info p {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: left;
}

.Hotel-service {
    position: relative;
    margin-top: 40px;
}

.Hotel-service hr {
    position: relative;
}



.label {
    /* border: 1px solid black; */
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    top: -15px;
}

.label h5 {
    border: 1px solid #ececec;
    background-color: #d2d2d2;
    color: #000000;
    border-radius: 20px;
    padding: 2px;
    padding-bottom: 8px;
    width: 90px;
}

.Hotel-service-logos {
    margin-top: 30px;
}

.Hotel-Card-T svg {
    font-size: 40px;
}


.Hotel-Card-B svg {
    font-size: 45px;
}

.Hotel-Card-M {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Hotel-Card-M h5 {
    margin-left: 12px;
}

.Hotel-Card-M svg {
    font-size: 45px;
}